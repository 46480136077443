<template>
  <div class="bdd-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select   w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="computedRole">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterType"
            :options="computedListType"
            text-field="text"
            value-field="value"
            @change="handlechangeFilialeSupport"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterType != null">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterStatut"
            :options="[
              { full_name: 'Tous', valus: null },
              ...computedValidationList
            ]"
            text-field="full_name"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <div
          v-if="getLoadingFraisVisionFiliale"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <!-- <refreshVisionViliale
            v-if="cantRefresh(this.$options.name) && this.filterMonth != null"
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @refreshData="handleFilter"
          /> -->
          <globalVlidation
            v-if="
              cantCreate(this.$options.name) &&
                notSupportedRole &&
                getFraisVisionFilialeData &&
                getFraisVisionFilialeData.length > 0 &&
                computedCheckedRows.length
            "
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @reloadData="reloadData"
            :dataToUse="computedFraisBdd"
          />
          <generation-facture-avoir-frais
            :interfaceName="this.$options.name"
            :filterType="filterType"
            :dataToUse="computedFraisBdd"
            @reloadData="reloadData"
          />
          <validation-statue-installateur
            v-if="
              cantUpdate(this.$options.name) &&
                filterType &&
                !isSuperAdmin &&
                computedCheckedRows.length
            "
            :filterType="filterType"
            :dataToUse="computedFraisBdd"
            @reloadData="reloadData"
          />
          <exractionBdd
            v-if="cantExport(this.$options.name)"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedFraisBdd"
            :filter="ComputedFilter"
            :selected="computedCheckedRows.length"
          />
          <div>
            <div
              v-if="getLoadingMail"
              class="chargement chargement-loading-icon"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="
                !getLoadingMail &&
                  computedCheckedRows &&
                  computedCheckedRows.length > 0
              "
            >
              <sendMailGestionFrais
                :type="filterType"
                :dataToUse="computedFraisBdd"
                v-if="!isSuperAdmin && cantSend(this.$options.name)"
              />
            </div>
          </div>
          <exractionBddInvalid
            v-if="
              cantExport(this.$options.name) &&
                filterType &&
                computedCheckedRows.length
            "
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedFraisBdd"
          />
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisBdd table-header"
          :class="{ customTableFraisBddRezise: this.filterType == null }"
          :items="computedFraisBdd"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          :sort-by.sync="sortBy"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <template v-if="filterType == null">
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == false
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"/>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == true
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"
              /></template>

              <span
                v-if="data.field.key != 'check_all'"
                :class="{
                  'white-color': computedHeaderVisibility(data.field.key)
                }"
              >
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
              v-if="data.item.projects.length != 0"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template>
          <template v-slot:cell(nom)="data">
            <span
              @click="handleDownloadExtraitInstalleur(data.item.nom)"
              :title="'Télécharger extrait ' + data.item.nom"
            >
              {{ data.item.nom }}
            </span>
          </template>
          <template v-slot:cell(operationnel)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'operationnel'
                })
              "
              >{{ data.item.operationnel }} €</span
            >
          </template>
          <template v-slot:cell(commercial)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial'
                })
              "
            >
              {{ data.item.commercial }} €</span
            >
          </template>
          <template v-slot:cell(numero_dossier)="">
            <span> -</span>
          </template>
          <template v-slot:cell(technique)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'technique'
                })
              "
            >
              {{ data.item.technique }} €</span
            >
          </template>
          <template v-slot:cell(marketing)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'marketing'
                })
              "
            >
              {{ data.item.marketing }} €</span
            >
          </template>
          <template v-slot:cell(rge)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'rge'
                })
              "
            >
              {{ data.item.rge }} €</span
            >
          </template>
          <template v-slot:cell(CDG)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'CDG'
                })
              "
            >
              {{ data.item.CDG }} €</span
            >
          </template>
          <template v-slot:cell(administratif_sav)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'administratif_sav'
                })
              "
            >
              {{ data.item.administratif_sav }} €</span
            >
          </template>
          <template v-slot:cell(dsi)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'dsi'
                })
              "
            >
              {{ data.item.dsi }} €</span
            >
          </template>
          <template v-slot:cell(juridique)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'juridique'
                })
              "
              >{{ data.item.juridique }} €</span
            >
          </template>
          <template v-slot:cell(alger)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'alger'
                })
              "
            >
              {{ data.item.alger }} €</span
            >
          </template>
          <template v-slot:cell(alteo_control_gestion)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'alteo_control_gestion'
                })
              "
            >
              {{ data.item.alteo_control_gestion }} €</span
            >
          </template>
          <template v-slot:cell(mairie)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'mairie'
                })
              "
            >
              {{ data.item.mairie }} €</span
            >
          </template>
          <template v-slot:cell(maintenance_pac)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'maintenance_pac'
                })
              "
            >
              {{ data.item.maintenance_pac }} €</span
            >
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }}
          </template>
          <template #row-details="data">
            <div v-if="data.item.loadingDetailt">
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </div>
            <span
              v-else
              v-for="(item, index) in data.item.projects"
              :key="data.item.nom + item.nom_pernom + index"
              class="align-td-item border-botom"
            >
              <td class="color">
                <div class="block-service-style">
                  <b-form-checkbox
                    v-model="item.check"
                    @change="checkAllVerifDetails(data.item, item)"
                    class="check-th-details ml-2"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="details-column-frais">
                <div class="block-service-style"></div>
              </td>
              <td class="installeur-column-frais">
                <div class="block-service-style">{{ item.nom_pernom }}</div>
              </td>
              <td class="item-details-style">
                {{ item.numero_dossier }}
              </td>
              <template
                v-if="
                  !computedFactureVide(
                    item.operationnel,
                    item.statue_operationnel
                  )
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('NOVA')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_operationnel == 'facture non validée',
                        'green-color': item.statue_operationnel == 'paye',
                        'red-color montant-bare':
                          item.statue_operationnel == 'annulee'
                      }"
                    >
                      {{ item.operationnel }} €
                    </span>
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="NOVAE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_operationnel == 'annulee'
                          ? false
                          : item.operationnel_download
                      "
                      :id_facture="item.operationnel_id"
                      :id_avoir="item.operationnel_av_id"
                      :editable="
                        item.statue_operationnel == 'paye' ||
                        item.statue_operationnel == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.operationnel_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'operationnel'
                      })
                    "
                    champName="operationnel"
                    :item="item"
                    :value="item.operationnel_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>

                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'operationnel'
                      })
                    "
                    champName="statue_operationnel"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.operationnel_id
                    }"
                    :value="item.statue_operationnel"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>

                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  {{ item.operationnel_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  {{ item.operationnel_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'operationnel'
                        })
                      "
                      champName="operationnel_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.operationnel_id
                      }"
                      :value="item.operationnel_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.operationnel_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  {{ item.operationnel_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  {{ item.operationnel_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  {{ item.operationnel_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('NOVA')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVA',
                      key: 'operationnel'
                    })
                  "
                >
                  -
                </td>
              </template>

              <!-- CACTUS -->
              <template
                v-if="
                  !computedFactureVide(item.commercial, item.statue_commercial)
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CACTUS')"
                >
                  <div class="block-service-style">
                    <div
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_commercial == 'facture non validée',
                        'green-color': item.statue_commercial == 'paye',
                        'red-color montant-bare':
                          item.statue_commercial == 'annulee'
                      }"
                    >
                      {{ item.commercial }} €
                    </div>
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      :type="'CACTUS'"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_commercial == 'annulee'
                          ? false
                          : item.commercial_download
                      "
                      :id_facture="item.commercial_id"
                      :id_avoir="item.commercial_av_id"
                      :editable="
                        item.statue_commercial == 'paye' ||
                        item.statue_commercial == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.commercial_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'commercial'
                      })
                    "
                    champName="commercial"
                    :item="item"
                    :value="item.commercial_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  <EditableInput
                    :editable="false"
                    champName="statue_commercial"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.commercial_id
                    }"
                    :value="item.statue_commercial"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>

                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  {{ item.commercial_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  {{ item.commercial_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="false"
                      champName="commercial_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.commercial_id
                      }"
                      :value="item.commercial_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.commercial_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  {{ item.commercial_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  {{ item.commercial_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  {{ item.commercial_av_numero }}
                </td>
              </template>

              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CACTUS')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CACTUS',
                      key: 'commercial'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- markiting -->
              <template
                v-if="
                  !computedFactureVide(item.marketing, item.statue_marketing)
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('AXELIUS')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_marketing == 'facture non validée',
                        'green-color': item.statue_marketing == 'paye',
                        'red-color montant-bare':
                          item.statue_marketing == 'annulee'
                      }"
                    >
                      {{ item.marketing }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="WEB ABONDAN"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_marketing == 'annulee'
                          ? false
                          : item.marketing_download
                      "
                      :id_facture="item.marketing_id"
                      :id_avoir="item.marketing_av_id"
                      :editable="
                        item.statue_marketing == 'paye' ||
                        item.statue_marketing == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.marketing_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'marketing'
                      })
                    "
                    champName="marketing"
                    :item="item"
                    :value="item.marketing_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'marketing'
                      })
                    "
                    champName="statue_marketing"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.marketing_id
                    }"
                    :value="item.statue_marketing"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  {{ item.marketing_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  {{ item.marketing_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'marketing'
                        })
                      "
                      champName="marketing_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.marketing_id
                      }"
                      :value="item.marketing_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.marketing_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  {{ item.marketing_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  {{ item.marketing_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  {{ item.marketing_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('AXELIUS')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'AXELIUS',
                      key: 'marketing'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- technique -->
              <template
                v-if="
                  !computedFactureVide(item.technique, item.statue_technique)
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('ISO 33')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_technique == 'facture non validée',
                        'green-color': item.statue_technique == 'paye',
                        'red-color montant-bare':
                          item.statue_technique == 'annulee'
                      }"
                    >
                      {{ item.technique }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="ISO 33"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_technique == 'annulee'
                          ? false
                          : item.technique_download
                      "
                      :id_facture="item.technique_id"
                      :id_avoir="item.technique_av_id"
                      :editable="
                        item.statue_technique == 'paye' ||
                        item.statue_technique == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.technique_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'technique'
                      })
                    "
                    champName="technique"
                    :item="item"
                    :value="item.technique_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'technique'
                      })
                    "
                    champName="statue_technique"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.technique_id
                    }"
                    :value="item.statue_technique"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  {{ item.technique_date }}
                </td>

                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  {{ item.technique_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'technique'
                        })
                      "
                      champName="technique_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.technique_id
                      }"
                      :value="item.technique_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.technique_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  {{ item.technique_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  {{ item.technique_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  {{ item.technique_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('ISO 33')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ISO 33',
                      key: 'technique'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- rge -->
              <template v-if="!computedFactureVide(item.rge, item.statue_rge)">
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CERTILIA')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color': item.statue_rge == 'facture non validée',
                        'green-color': item.statue_rge == 'paye',
                        'red-color montant-bare': item.statue_rge == 'annulee'
                      }"
                    >
                      {{ item.rge }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="RGE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_rge == 'annulee' ? false : item.rge_download
                      "
                      :id_facture="item.rge_id"
                      :id_avoir="item.rge_av_id"
                      :editable="
                        item.statue_rge == 'paye' ||
                        item.statue_rge == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.rge_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'rge'
                      })
                    "
                    champName="rge"
                    :item="item"
                    :value="item.rge_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'rge'
                      })
                    "
                    champName="statue_rge"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.rge_id
                    }"
                    :value="item.statue_rge"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  {{ item.rge_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  {{ item.rge_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'rge'
                        })
                      "
                      champName="rge_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.rge_id
                      }"
                      :value="item.rge_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component :item="item.rge_responsable_email" />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  {{ item.rge_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  {{ item.rge_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  {{ item.rge_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CERTILIA')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CERTILIA',
                      key: 'rge'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- dnh -->
              <template v-if="!computedFactureVide(item.CDG, item.statue_CDG)">
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('GE CDG')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color': item.statue_CDG == 'facture non validée',
                        'green-color': item.statue_CDG == 'paye',
                        'red-color montant-bare': item.statue_CDG == 'annulee'
                      }"
                    >
                      {{ item.CDG }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="DNH"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_CDG == 'annulee' ? false : item.CDG_download
                      "
                      :id_facture="item.CDG_id"
                      :id_avoir="item.CDG_av_id"
                      :editable="
                        item.statue_CDG == 'paye' ||
                        item.statue_CDG == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.CDG_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'CDG'
                      })
                    "
                    champName="CDG"
                    :item="item"
                    :value="item.CDG_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'CDG'
                      })
                    "
                    champName="statue_CDG"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.CDG_id
                    }"
                    :value="item.statue_CDG"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  {{ item.CDG_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  {{ item.CDG_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'CDG'
                        })
                      "
                      champName="CDG_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.CDG_id
                      }"
                      :value="item.CDG_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component :item="item.CDG_responsable_email" />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  {{ item.CDG_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  {{ item.CDG_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  {{ item.CDG_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('GE CDG')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GE CDG',
                      key: 'CDG'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- sav-s -->
              <template
                v-if="
                  !computedFactureVide(
                    item.administratif_sav,
                    item.statue_administratif_sav
                  )
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('SAMI-B')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_administratif_sav ==
                          'facture non validée',
                        'green-color': item.statue_administratif_sav == 'paye',
                        'red-color montant-bare':
                          item.statue_administratif_sav == 'annulee'
                      }"
                    >
                      {{ item.administratif_sav }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="SAV-S"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_administratif_sav == 'annulee'
                          ? false
                          : item.administratif_sav_download
                      "
                      :id_facture="item.administratif_sav_id"
                      :id_avoir="item.administratif_sav_av_id"
                      :editable="
                        item.statue_administratif_sav == 'paye' ||
                        item.statue_administratif_sav == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.administratif_sav_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'administratif_sav'
                      })
                    "
                    champName="administratif_sav"
                    :item="item"
                    :value="item.administratif_sav_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'administratif_sav'
                      })
                    "
                    champName="statue_administratif_sav"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.administratif_sav_id
                    }"
                    :value="item.statue_administratif_sav"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  {{ item.administratif_sav_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  {{ item.administratif_sav_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'administratif_sav'
                        })
                      "
                      champName="administratif_sav_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.administratif_sav_id
                      }"
                      :value="item.administratif_sav_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.administratif_sav_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  {{ item.administratif_sav_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  {{ item.administratif_sav_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  {{ item.administratif_sav_av_numero }}
                </td>
              </template>

              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('SAMI-B')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'SAMI-B',
                      key: 'administratif_sav'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- web abondance -->
              <template v-if="!computedFactureVide(item.dsi, item.statue_dsi)">
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('WEB ABONDANCE')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color': item.statue_dsi == 'facture non validée',
                        'green-color': item.statue_dsi == 'paye',
                        'red-color montant-bare': item.statue_dsi == 'annulee'
                      }"
                    >
                      {{ item.dsi }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="WEB ABONDANCE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_dsi == 'annulee' ? false : item.dsi_download
                      "
                      :id_facture="item.dsi_id"
                      :id_avoir="item.dsi_av_id"
                      :editable="
                        item.statue_dsi == 'paye' ||
                        item.statue_dsi == 'annulee' ||
                        isResposableWebAbondance
                          ? false
                          : true
                      "
                      :sendMailDate="item.dsi_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'dsi'
                      })
                    "
                    champName="dsi"
                    :item="item"
                    :value="item.dsi_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'dsi'
                      }) && !isResposableWebAbondance
                    "
                    champName="statue_dsi"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.dsi_id
                    }"
                    :value="item.statue_dsi"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'dsi'
                        }) && !isResposableWebAbondance
                      "
                      champName="dsi_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.dsi_id
                      }"
                      :value="item.dsi_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component :item="item.dsi_responsable_email" />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('WEB ABONDANCE')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
              </template>

              <!-- CORPEDYS -->
              <template
                v-if="
                  !computedFactureVide(item.juridique, item.statue_juridique)
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CORPEDYS')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_juridique == 'facture non validée',
                        'green-color': item.statue_juridique == 'paye',
                        'red-color montant-bare':
                          item.statue_juridique == 'annulee'
                      }"
                      >{{ item.juridique }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="CORPEDYS"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_juridique == 'annulee'
                          ? false
                          : item.juridique_download
                      "
                      :id_facture="item.juridique_id"
                      :id_avoir="item.juridique_av_id"
                      :editable="
                        item.statue_juridique == 'paye' ||
                        item.statue_juridique == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.juridique_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'juridique'
                      })
                    "
                    champName="juridique"
                    :item="item"
                    :value="item.juridique_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'juridique'
                      })
                    "
                    champName="statue_juridique"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.juridique_id
                    }"
                    :value="item.statue_juridique"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  {{ item.juridique_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  {{ item.juridique_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'juridique'
                        })
                      "
                      champName="juridique_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.juridique_id
                      }"
                      :value="item.juridique_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.juridique_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  {{ item.juridique_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  {{ item.juridique_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  {{ item.juridique_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('CORPEDYS')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'CORPEDYS',
                      key: 'juridique'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- ALTEO France -->
              <template
                v-if="!computedFactureVide(item.alger, item.statue_alger)"
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('ALTEO France')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color': item.statue_alger == 'facture non validée',
                        'green-color': item.statue_alger == 'paye',
                        'red-color montant-bare': item.statue_alger == 'annulee'
                      }"
                    >
                      {{ item.alger }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="ACTEO"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_alger == 'annulee'
                          ? false
                          : item.alger_download
                      "
                      :id_facture="item.alger_id"
                      :id_avoir="item.alger_av_id"
                      :editable="
                        item.statue_alger == 'paye' ||
                        item.statue_alger == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.alger_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'alger'
                      })
                    "
                    champName="alger"
                    :item="item"
                    :value="item.alger_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'alger'
                      })
                    "
                    champName="statue_alger"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.alger_id
                    }"
                    :value="item.statue_alger"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  {{ item.alger_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  {{ item.alger_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'alger'
                        })
                      "
                      champName="alger_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.alger_id
                      }"
                      :value="item.alger_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component :item="item.alger_responsable_email" />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  {{ item.alger_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  {{ item.alger_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  {{ item.alger_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('ALTEO France')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO France',
                      key: 'alger'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- ALTEO CONTROLE DE GESTION -->
              <template
                v-if="
                  !computedFactureVide(
                    item.alteo_control_gestion,
                    item.statue_alteo_control_gestion
                  )
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('Alteo contrôle qualité')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_alteo_control_gestion ==
                          'facture non validée',
                        'green-color':
                          item.statue_alteo_control_gestion == 'paye',
                        'red-color montant-bare':
                          item.statue_alteo_control_gestion == 'annulee'
                      }"
                    >
                      {{ item.alteo_control_gestion }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="alteo_control_gestion"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_alteo_control_gestion == 'annulee'
                          ? false
                          : item.alteo_control_gestion_download
                      "
                      :id_facture="item.alteo_control_gestion_id"
                      :id_avoir="item.alteo_control_gestion_av_id"
                      :editable="
                        item.statue_alteo_control_gestion == 'paye' ||
                        item.statue_alteo_control_gestion == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.alteo_control_gestion_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'alteo_control_gestion'
                      })
                    "
                    champName="alteo_control_gestion"
                    :item="item"
                    :value="item.alteo_control_gestion_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'alteo_control_gestion'
                      })
                    "
                    champName="statue_alteo_control_gestion"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.alteo_control_gestion_id
                    }"
                    :value="item.statue_alteo_control_gestion"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  {{ item.alteo_control_gestion_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  {{ item.alteo_control_gestion_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'alteo_control_gestion'
                        })
                      "
                      champName="alteo_control_gestion_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.alteo_control_gestion_id
                      }"
                      :value="item.alteo_control_gestion_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.alteo_control_gestion_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  {{ item.alteo_control_gestion_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  {{ item.alteo_control_gestion_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  {{ item.alteo_control_gestion_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('Alteo contrôle qualité')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'Alteo contrôle qualité',
                      key: 'alteo_control_gestion'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- mairie -->
              <template
                v-if="!computedFactureVide(item.mairie, item.statue_mairie)"
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('NOVAE MAIRIE')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_mairie == 'facture non validée',
                        'green-color': item.statue_mairie == 'paye',
                        'red-color montant-bare':
                          item.statue_mairie == 'annulee'
                      }"
                    >
                      {{ item.mairie }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="NOVAE MAIRIE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_mairie == 'annulee'
                          ? false
                          : item.mairie_download
                      "
                      :id_facture="item.mairie_id"
                      :id_avoir="item.mairie_av_id"
                      :editable="
                        item.statue_mairie == 'paye' ||
                        item.statue_mairie == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.mairie_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'mairie'
                      })
                    "
                    champName="mairie"
                    :item="item"
                    :value="item.mairie_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'mairie'
                      })
                    "
                    champName="statue_mairie"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.mairie_id
                    }"
                    :value="item.statue_mairie"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  {{ item.mairie_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  {{ item.mairie_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'mairie'
                        })
                      "
                      champName="mairie_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.mairie_id
                      }"
                      :value="item.mairie_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.mairie_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  {{ item.mairie_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  {{ item.mairie_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  {{ item.mairie_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('NOVAE MAIRIE')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'NOVAE MAIRIE',
                      key: 'mairie'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- GSE -->
              <template
                v-if="
                  !computedFactureVide(
                    item.maintenance_pac,
                    item.statue_maintenance_pac
                  )
                "
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('GSE')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_maintenance_pac == 'facture non validée',
                        'green-color': item.statue_maintenance_pac == 'paye',
                        'red-color montant-bare':
                          item.statue_maintenance_pac == 'annulee'
                      }"
                    >
                      {{ item.maintenance_pac }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="GSE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_maintenance_pac == 'annulee'
                          ? false
                          : item.maintenance_pac_download
                      "
                      :id_facture="item.maintenance_pac_id"
                      :id_avoir="item.maintenance_pac_av_id"
                      :editable="
                        item.statue_maintenance_pac == 'paye' ||
                        item.statue_maintenance_pac == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.maintenance_pac_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCreateLcr({
                        item: item,
                        column: 'maintenance_pac'
                      })
                    "
                    champName="maintenance_pac"
                    :item="item"
                    :value="item.maintenance_pac_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'maintenance_pac'
                      })
                    "
                    champName="statue_maintenance_pac"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.maintenance_pac_id
                    }"
                    :value="item.statue_maintenance_pac"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  {{ item.maintenance_pac_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  {{ item.maintenance_pac_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'maintenance_pac'
                        })
                      "
                      champName="maintenance_pac_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.maintenance_pac_id
                      }"
                      :value="item.maintenance_pac_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.maintenance_pac_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  {{ item.maintenance_pac_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  {{ item.maintenance_pac_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  {{ item.maintenance_pac_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('GSE')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'GSE',
                      key: 'maintenance_pac'
                    })
                  "
                >
                  -
                </td>
              </template>

              <td
                class=" item-details-style justify-content-sb-frais-globale"
                v-if="filterType == null"
              ></td>
            </span>
          </template>
          <!-- <template
            #table-caption
            v-if="
              getTotalVariableFraisVisionFiliale != null &&
                getFraisVisionFilialeData.length
            "
          >
            <b-tr class="tr-generale">
              <b-td class="color footer-total-bg"> </b-td>
              <b-td class=" footer-total-bg details-column-frais"> </b-td>
              <b-td class=" installeur-column-frais footer-total-bg ">
                Total général</b-td
              >
              <b-td class="footer-total-bg"> </b-td>
              <b-td
                v-if="filterType == null || filterType == 'NOVA'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_operationnel }} €
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('statue_operationnel') ||
                    filterType == 'NOVA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('operationnel_date') || filterType == 'NOVA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('operationnel_numero') ||
                    filterType == 'NOVA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                   <b-td
                v-if="
                  visibilityColumn('operationnel_statue_installateur') ||
                    filterType == 'NOVA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              
              <b-td
                v-if="filterType == null || filterType == 'CACTUS'"
                class="td-width-dynamyque"
                >{{
                  getTotalVariableFraisVisionFiliale.total_commercial
                }}
                €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_commercial') ||
                    filterType == 'CACTUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('commercial_date') || filterType == 'CACTUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('commercial_numero') ||
                    filterType == 'CACTUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                <b-td
                v-if="
                  visibilityColumn('commercial_statue_installateur') ||
                    filterType == 'CACTUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'AXELIUS'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_marketing }} €
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('statue_marketing') ||
                    filterType == 'AXELIUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('marketing_date') || filterType == 'AXELIUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('marketing_numero') ||
                    filterType == 'AXELIUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
               <b-td
                v-if="
                  visibilityColumn('marketing_statue_installateur') ||
                    filterType == 'AXELIUS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              
              <b-td
                v-if="filterType == null || filterType == 'ISO 33'"
                class="td-width-dynamyque"
                >{{
                  getTotalVariableFraisVisionFiliale.total_technique
                }}
                €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_technique') || filterType == 'ISO 33'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('technique_date') || filterType == 'ISO 33'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('technique_numero') || filterType == 'ISO 33'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                   <b-td
                v-if="
                  visibilityColumn('rge_statue_installateur') || filterType == 'ISO 33'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                <b-td
                v-if="
                  visibilityColumn('technique_statue_installateur') || filterType == 'ISO 33'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'CERTILIA'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_rge }} €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_rge') || filterType == 'CERTILIA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="visibilityColumn('rge_date') || filterType == 'CERTILIA'"
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('rge_numero') || filterType == 'CERTILIA'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'GE CDG'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_CDG }} €</b-td
              >
              <b-td
                v-if="visibilityColumn('statue_CDG') || filterType == 'GE CDG'"
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="visibilityColumn('CDG_date') || filterType == 'GE CDG'"
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="visibilityColumn('CDG_numero') || filterType == 'GE CDG'"
                class="td-width-dynamyque"
              >
              </b-td>
               <b-td
                v-if="visibilityColumn('CDG_statue_installateur') || filterType == 'GE CDG'"
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'SAMI-B'"
                class="td-width-dynamyque"
                >{{
                  getTotalVariableFraisVisionFiliale.total_administratif_sav
                }}
                €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_administratif_sav') ||
                    filterType == 'SAMI-B'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('administratif_sav_date') ||
                    filterType == 'SAMI-B'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('administratif_sav_numero') ||
                    filterType == 'SAMI-B'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                   <b-td
                v-if="
                  visibilityColumn('administratif_sav_statue_installateur') ||
                    filterType == 'SAMI-B'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'WEB ABONDANCE'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_dsi }} €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_dsi') ||
                    filterType == 'WEB ABONDANCE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('dsi_date') || filterType == 'WEB ABONDANCE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('dsi_numero') ||
                    filterType == 'WEB ABONDANCE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('dsi_statue_installateur') ||
                    filterType == 'WEB ABONDANCE'
                "
                class="td-width-dynamyque"
              >

              <b-td v-if="filterType == null"
                >{{ getTotalVariableFraisVisionFiliale.total }} €</b-td
              >
              <b-td
                v-if="filterType == null || filterType == 'CORPEDYS'"
                class="td-width-dynamyque"
                >{{
                  getTotalVariableFraisVisionFiliale.total_juridique
                }}
                €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_juridique') ||
                    filterType == 'CORPEDYS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('juridique_date') || filterType == 'CORPEDYS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('juridique_numero') ||
                    filterType == 'CORPEDYS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('juridique_statue_installateur') ||
                    filterType == 'CORPEDYS'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'ALTEO France'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_alger }} €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_alger') ||
                    filterType == 'ALTEO France'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('alger_date') || filterType == 'ALTEO France'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('alger_numero') ||
                    filterType == 'ALTEO France'
                "
                class="td-width-dynamyque"
              >
              </b-td>
               <b-td
                v-if="
                  visibilityColumn('alger_statue_installateur') ||
                    filterType == 'ALTEO France'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'NOVAE MAIRIE'"
                class="td-width-dynamyque"
                >{{ getTotalVariableFraisVisionFiliale.total_mairie }} €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_mairie') ||
                    filterType == 'NOVAE MAIRIE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('mairie_date') ||
                    filterType == 'NOVAE MAIRIE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('mairie_numero') ||
                    filterType == 'NOVAE MAIRIE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
                            <b-td
                v-if="
                  visibilityColumn('mairie_statue_installateur') ||
                    filterType == 'NOVAE MAIRIE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="filterType == null || filterType == 'GSE'"
                class="td-width-dynamyque"
                >{{
                  getTotalVariableFraisVisionFiliale.total_maintenance_pac
                }}
                €</b-td
              >
              <b-td
                v-if="
                  visibilityColumn('statue_maintenance_pac') ||
                    filterType == 'GSE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('maintenance_pac_date') ||
                    filterType == 'GSE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('maintenance_pac_numero') ||
                    filterType == 'GSE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
              <b-td
                v-if="
                  visibilityColumn('maintenance_pac_statue_installateur') ||
                    filterType == 'GSE'
                "
                class="td-width-dynamyque"
              >
              </b-td>
            </b-tr>
          </template> -->
        </b-table>
        <div class=" footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getFraisVisionFilialeRows"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div v-if="computedTotal > 0" class="style-somme-th-ttc">
        <p class="m-2">
          Total ht:
          {{ computedTotal.toFixed(2).toLocaleString('en-US') }}€
          {{ '   ' }}Total ttc:

          {{ computedTotalTTC.toFixed(2).toLocaleString('en-US') }}€
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';

export default {
  name: 'Récapitulatif-gestion-des-frais',
  data() {
    return {
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      sommeSelected: 0,
      filterStatut: null,
      flashMessageId: null,
      filterWeek: null,
      filterDepot: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterFiliale: null,
      filterType: null,
      validIds: [],
      fields: [
        {
          key: 'check_all',
          label: '',
          views: true,
          tdClass: 'color',
          thClass: 'th-color',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais',
          views: true,
          for: 'all'
        },
        {
          key: 'nom',
          label: 'Filiale Installateur',
          thClass: 'th-installeur-column-frais',
          tdClass: 'installeur-column-frais',
          views: true,
          for: 'all'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          views: true,
          for: 'all'
        },
        {
          key: 'operationnel',
          label: 'Gestion Opérationnel (NOVAE)',
          views: true,
          details: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'operationnel_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'statue_operationnel',
          label: 'Statut facture Gestion Opérationnell ',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'operationnel_date',
          label: 'Date facture Gestion Opérationnel ',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'operationnel_numero',
          label: 'Numéro de facture ',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'operationnel_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
        },
        {
          key: 'operationnel_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
          // showInAdmin: 'non'
        },
        {
          key: 'operationnel_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
          // showInAdmin: 'non'
        },
        {
          key: 'operationnel_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-operationnel',
          for: 'NOVA'
          // showInAdmin: 'non'
        },
        //
        {
          key: 'commercial',
          label: 'Gestion Commercial (CACTUS)',
          views: true,
          details: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'commercial_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'statue_commercial',
          label: 'Statut facture Gestion Commercial ',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'commercial_date',
          label: 'Date facture Gestion Commercial ',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'commercial_numero',
          label: 'Numéro de facture ',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'commercial_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
        },
        {
          key: 'commercial_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
          // showInAdmin: 'non'
        },
        {
          key: 'commercial_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
          // showInAdmin: 'non'
        },
        {
          key: 'commercial_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-commercial',
          for: 'CACTUS'
          // showInAdmin: 'non'
        },
        //
        {
          key: 'marketing',
          label: 'Gestion Marketing (PINKMAN)',
          views: true,
          details: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'statue_marketing',
          label: 'Statut facture Gestion Marketing',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_date',
          label: 'Date facture  Gestion Marketing',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        {
          key: 'marketing_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS'
        },
        //
        {
          key: 'technique',
          label: 'Gestion Technique (KYNAN)',
          views: true,
          details: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'statue_technique',
          label: 'Statut facture Gestion Technique ',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_date',
          label: 'Date facture  Gestion Technique ',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        {
          key: 'technique_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-technique',
          for: 'ISO 33'
        },
        //
        {
          key: 'rge',
          label: 'Gestion RGE (CERTILIA)',
          views: true,
          details: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'statue_rge',
          label: 'Statut facture Gestion RGE',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_date',
          label: 'Date facture Gestion RGE',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        {
          key: 'rge_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-rge',
          for: 'CERTILIA'
        },
        //
        {
          key: 'CDG',
          label: 'CDG (GE CDG)',
          views: true,
          details: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'statue_CDG',
          label: 'Statut facture CDG',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_date',
          label: 'Date facture  CDG',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        {
          key: 'CDG_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-cdg',
          for: 'GE CDG'
        },
        //
        {
          key: 'administratif_sav',
          label: 'Gestion Administratif - SAV  (SAMI-B)',
          views: true,
          details: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'statue_administratif_sav',
          label: 'Statut facture Gestion Administratif - SAV',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_date',
          label: 'Date facture  Gestion Administratif - SAV',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        {
          key: 'administratif_sav_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-administratif-sav',
          for: 'SAMI-B'
        },
        //
        {
          key: 'dsi',
          label: 'DSI  (WEB ABONDANCE)',
          views: true,
          details: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'statue_dsi',
          label: 'Statut facture DSI',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_date',
          label: 'Date facture  DSI',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        //
        {
          key: 'juridique',
          label: 'Juridique (CORPEDYS)',
          views: true,
          details: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'statue_juridique',
          label: 'Statut facture Juridique',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_date',
          label: 'Date facture Juridique',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        {
          key: 'juridique_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-juridique',
          for: 'CORPEDYS'
        },
        //
        {
          key: 'alger',
          label: 'Alger - Gestion (ALTEO France)',
          views: true,
          details: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'statue_alger',
          label: 'Statut facture Alger',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_date',
          label: 'Date facture Alger ',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        {
          key: 'alger_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'ALTEO France'
        },
        //
        //alteo controle de gestion
        {
          key: 'alteo_control_gestion',
          label: 'Alteo contrôle qualité',
          views: true,
          details: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'statue_alteo_control_gestion',
          label: 'Statut facture',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_date',
          label: 'Date facture',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        {
          key: 'alteo_control_gestion_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-alger',
          for: 'Alteo contrôle qualité'
        },
        //
        {
          key: 'mairie',
          label: 'Mairie (NOVAE MAIRIE)',
          views: true,
          details: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'statue_mairie',
          label: 'Statut facture Mairie',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_date',
          label: 'Date facture Mairie',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        {
          key: 'mairie_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-marie',
          for: 'NOVAE MAIRIE'
        },
        //
        {
          key: 'maintenance_pac',
          label: 'Maintenace PAC (GSE)',
          views: true,
          details: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'statue_maintenance_pac',
          label: 'Statut facture Maintenace PAC',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_date',
          label: 'Date facture Maintenace PAC',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        {
          key: 'maintenance_pac_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-maintenance-pac',
          for: 'GSE'
        },
        { key: 'total', label: 'Total à payer', views: true }
      ],
      listType: [
        { value: 'NOVA', text: 'NOVAE' },
        { value: 'CACTUS', text: 'CACTUS' },
        { value: 'AXELIUS', text: 'PINKMAN' },
        { value: 'ISO 33', text: 'KYNAN technique' },
        { value: 'CERTILIA', text: 'CERTILIA' },
        { value: 'GE CDG', text: 'GE CDG' },
        { value: 'SAMI-B', text: 'SAMI-B' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ALTEO France', text: 'ALTEO France' },
        {
          value: 'Alteo contrôle qualité',
          text: 'Alteo contrôle qualité'
        },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' }
        // { value: 'UPCUBE TECHNOLOGIES', text: 'UPCUBE TECHNOLOGIES' }
      ],
      userRole: [
        'responsable_novae',
        'responsable_cactus',
        'responsable_web_abondan',
        'responsable_iso_33',
        'responsable_rge',
        'responsable_dnh',
        'responsable_sav_s',
        'responsable_web_abondance',
        'responsable_corpedys',
        'responsable_acteo',
        'responsable_bureau_etude',
        'responsable_mairie',
        'responsable_gse'
      ],
      typeArray: [
        'operationnel',
        'commercial',
        'marketing',
        'technique',
        'rge',
        'CDG',
        'administratif_sav',
        'dsi',
        'juridique',
        'alger',
        'alteo_control_gestion',
        'mairie',
        'maintenance_pac'
      ],
      sortBy: 'nom',
      searchValue: null,
      checkAll: false
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getLoadingFraisVisionFiliale',
      'getFraisVisionFilialeData',
      'cantUpdate',
      'cantExport',
      'cantCreate',
      'getTotalVariableFraisVisionFiliale',
      'getErrorFraisVisionFiliale',
      'getUserData',
      'cantRefresh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'cantSend',
      'getLoadingMail',
      'isResposableWebAbondance',
      'isResponsableFiliale',
      'getFraisVisionFilialeRows'
    ]),
    computedTotal() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedTotalTTC() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                        .replace(' ', '')
                        .replace(',', '.')
                    ) *
                      1.2 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedSelectedValidids() {
      let dataToUse = this.dataToUse.filter(k => {
        return k.projects.some(e => e.check == true);
      });
      // dataToUse = dataToUse.sort((a, b) => a.nom.localeCompare(b.nom));
      let indexToUse = 0;
      for (let j = 0; j < dataToUse.length; j++) {
        for (let i = 0; i < dataToUse[j].projects.length; i++) {
          if (this.type != null) {
            if (
              dataToUse[j].projects[i].check == true &&
              dataToUse[j].projects[i][this.computedColumnIdProject] &&
              dataToUse[j].projects[i][this.computedColumnIdProject] != null
            ) {
              validIds.push(
                dataToUse[j].projects[i][this.computedColumnIdProject]
              );
            }
          }
          indexToUse = indexToUse + 1;
        }
      }
      return validIds;
    },
    computedEditableStatutInstallateurFrais: function() {
      return function(data) {
        if (data) {
          if (
            this.cantUpdate(this.$options.name) &&
            (this.isResponsableFiliale || this.isSuperAdmin) &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true &&
            data.item[data.column + '_statue_installateur'] != 'paye'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedEditableStatutFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdmin) {
            return true;
          }
          if (
            this.cantUpdate(this.$options.name) &&
            !this.isSuperAdmin &&
            !this.isResponsableFiliale &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedCreateLcr: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdmin) {
            return true;
          }
          if (
            this.cantCreate(this.$options.name) &&
            !this.isSuperAdmin &&
            !this.isResponsableFiliale &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedColumn() {
      switch (this.filterType) {
        case 'NOVA':
          return 'operationnel';
        case 'CACTUS':
          return 'commercial';
        case 'AXELIUS':
          return 'marketing';
        case 'ISO 33':
          return 'technique';
        case 'CERTILIA':
          return 'rge';
        case 'GE CDG':
          return 'CDG';
        case 'SAMI-B':
          return 'administratif_sav';
        case 'WEB ABONDANCE':
          return 'dsi';
        case 'CORPEDYS':
          return 'juridique';
        case 'ALTEO France':
          return 'alger';
        case 'ALTEO controle de gestion':
          return 'alteo_control_gestion';
        case 'NOVAE MAIRIE':
          return 'mairie';
        case 'GSE':
          return 'maintenance_pac';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion';
        default:
          return this.filterType;
      }
    },
    // computedSommeCheked() {},
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedFraisBdd() {
      return this.getFraisVisionFilialeData;
    },
    computedFields() {
      switch (this.filterType) {
        case 'NOVA':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'NOVA'
          );
        case 'CACTUS':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'CACTUS'
          );
        case 'AXELIUS':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'AXELIUS'
          );
        case 'ISO 33':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'ISO 33'
          );
        case 'CERTILIA':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'CERTILIA'
          );
        case 'GE CDG':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'GE CDG'
          );
        case 'SAMI-B':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'SAMI-B'
          );
        case 'WEB ABONDANCE':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'WEB ABONDANCE'
          );
        case 'CORPEDYS':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'CORPEDYS'
          );
        case 'ALTEO France':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'ALTEO France'
          );
        case 'NOVAE MAIRIE':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'NOVAE MAIRIE'
          );
        case 'GSE':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'GSE'
          );
        case 'UPCUBE TECHNOLOGIES':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'UPCUBE'
          );
        case 'Alteo contrôle qualité':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'Alteo contrôle qualité'
          );
        default:
          if (
            this.getUserData &&
            this.getUserData.role &&
            this.getUserData.role == 'responsable_bureau_etude'
          ) {
            return this.fields.filter(
              item => item.for == 'all' || item.for == 'ISO 33'
            );
          }
          if (
            this.getUserData &&
            this.getUserData.role &&
            this.getUserData.role == 'responsable_novae'
          ) {
            return this.fields.filter(
              item =>
                item.views == true &&
                (item.for == 'all' ||
                  item.for == 'NOVA' ||
                  item.for == 'NOVAE MAIRIE')
            );
          }
          return this.fields.filter(item => item.views == true);
      }
    },
    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    isSuperAdmin() {
      return this.getUserData?.role && this.getUserData?.role == 'admin';
    },
    notSupportedRole() {
      if (
        this.isResposableWebAbondance &&
        this.filterType == 'UPCUBE TECHNOLOGIES'
      ) {
        return false;
      }
      return this.getUserData?.role && this.getUserData.role != 'admin';
    },
    computedRole() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.userRole.includes(this.getUserData.role) &&
        this.getUserData.role != 'responsable_novae' &&
        this.getUserData.role != 'responsable_web_abondance' &&
        this.getUserData.role != 'responsable_acteo'
      ) {
        return false;
      }
      return true;
    },
    computedShowDetailsColomn() {
      return function(data) {
        if (data && data.key) {
          if (
            (this.filterType == null &&
              this.fields.find(
                item => item.key == data.key && item.details == true
              )) ||
            this.filterType == data.value
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.filterType == null || this.filterType == data) {
            return true;
          } else {
            return false;
          }
        }
      };
    },
    visibilityColumn() {
      return function(key) {
        if (
          this.fields.find(item => item.key == key && item.views == true) &&
          this.filterType == null
        ) {
          return true;
        }
        return false;
      };
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'operationnel' ||
          data == 'commercial' ||
          data == 'marketing' ||
          data == 'technique' ||
          data == 'rge' ||
          data == 'CDG' ||
          data == 'administratif_sav' ||
          data == 'dsi' ||
          data == 'juridique' ||
          data == 'alger' ||
          data == 'alteo_control_gestion' ||
          data == 'mairie' ||
          data == 'maintenance_pac'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedListType() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_bureau_etude'
      ) {
        return this.listType.filter(item => item.value == 'ISO 33');
      }
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_novae'
      ) {
        return this.listType.filter(
          item => item.value == 'NOVA' || item.value == 'NOVAE MAIRIE'
        );
      }
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_web_abondance'
      ) {
        return this.listType.filter(
          item =>
            item.value == 'WEB ABONDANCE' || item.value == 'UPCUBE TECHNOLOGIES'
        );
      }
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_acteo'
      ) {
        return this.listType.filter(
          item =>
            item.value == 'ALTEO France' ||
            item.value == 'Alteo contrôle qualité'
        );
      }
      return [{ value: null, text: 'Tous' }, ...this.listType];
    },
    computedResppnsableNovae() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_novae'
      ) {
        return true;
      }
      return false;
    },
    computedCheckedRows() {
      return this.getFraisVisionFilialeData.filter(k => {
        return k.projects.some(e => e.check == true);
      });
    },
    computedFactureVide() {
      return function(montant, statue) {
        if (
          statue == 'facture non validée' &&
          (montant == '0.00' ||
            montant == '0,00' ||
            montant == '0' ||
            montant == 0)
        ) {
          return true;
        }
        return false;
      };
    },
    computedType() {
      switch (this.filterType) {
        case 'NOVA':
          return 'NOVAE';
        case 'AXELIUS':
          return 'WEB ABONDAN';
        case 'ISO 33':
          return 'ISO 33';
        case 'CERTILIA':
          return 'RGE';
        case 'GE CDG':
          return 'DNH';
        case 'SAMI-B':
          return 'SAV-S';
        case 'WEB ABONDANCE':
          return 'WEB ABONDANCE';
        case 'CORPEDYS':
          return 'CORPEDYS';
        case 'ALTEO France':
          return 'ACTEO';
        case 'GE COMPTA':
          return 'GE COMPTA';
        case 'GE RH':
          return 'GE RH';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'KYNAN - ISO 33 - ISAUDIT';
        case 'NOVAE MAIRIE':
          return 'NOVAE MAIRIE';
        case 'GSE':
          return 'GSE';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion';
        default:
          return this.filterType;
      }
    },
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType
      };
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllFraisVisionFiliale',
      'updateStatueFraisVisionFelialeService',
      'downloadExtraitInstalleurAction',
      'downloadExtraitSupportServiceAction',
      'updateStatueInstallateurFraisVisionFelialeService',
      'updateInterface',
      'updattypePaiementFrais',
      'getDataForRowFrais'
    ]),
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowFrais({
          item: data.item,
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          week: this.filterWeek,
          depot: this.filterDepot,
          statue: this.filterStatut,
          type: this.computedType,
          page: this.page,
          perPage: this.perPage
        });
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    rowClass(item, rowType) {
      if (rowType === 'row-details') {
        return 'subLigneEnCourDeValidation';
      }
      return 'ligneEnCourDeValidation';
    },
    handleDownloadExtraitInstalleur(item) {
      if (this.filterMonth != null && this.filterType == null) {
        this.$confirm(
          '',
          'Êtes-vous sur de vouloir télecharger tous les extraits pour ' +
            item +
            ' ?',
          'warning'
        )
          .then(() => {
            this.downloadExtraitInstalleurAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item,
              type: this.typeArray
            });
          })
          .catch(() => {});
      }
    },
    handleDownloadExtraitSupportService(item) {
      if (this.filterMonth != null) {
        this.downloadExtraitSupportServiceAction({
          year: this.filteryear,
          month: this.filterMonth,
          filiale: item.item.nom,
          type: item.type
        });
      }
    },
    setVisibilityFieldsHeader(key) {
      this.fields.map(item => {
        if (item.key == key) {
          item.details = !item.details;
        }
        if (
          item.key == key + '_type_paiement' ||
          item.key == key + '_date' ||
          item.key == 'statue_' + key ||
          item.key == key + '_numero' ||
          item.key == key + '_av_numero' ||
          item.key == key + '_statue_installateur' ||
          item.key == key + '_email_sent_at' ||
          item.key == key + '_lot'
        ) {
          item.views = !item.views;
        }
      });
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handleChangeDepot() {
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handlechangeFilialeSupport() {
      this.sommeSelected = 0;
      this.filterStatut = null;
      this.handleFilter();
      this.setLocalStorageVisionFiliale();
    },
    handleFilter() {
      this.getAllFraisVisionFiliale({
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType,
        page: this.page,
        perPage: this.perPage
      });
      this.setLocalStorageVisionFiliale();
    },
    reloadData() {
      this.checkAll = false;
      this.filterStatut = null;
      this.handleFilter();
      this.sommeSelected = 0;
    },
    checkRole() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.userRole.includes(this.getUserData.role)
      ) {
        switch (this.getUserData.role) {
          case 'responsable_novae':
            this.filterType = 'NOVA';
            break;
          case 'responsable_cactus':
            this.filterType = 'CACTUS';
            break;
          case 'responsable_web_abondan':
            this.filterType = 'AXELIUS';
            break;
          case 'responsable_iso_33':
            this.filterType = 'ISO 33';
            break;
          case 'responsable_rge':
            this.filterType = 'CERTILIA';
            break;
          case 'responsable_dnh':
            this.filterType = 'GE CDG';
            break;
          case 'responsable_sav_s':
            this.filterType = 'SAMI-B';
            break;
          case 'responsable_web_abondance':
            this.filterType = 'WEB ABONDANCE';
            break;
          case 'responsable_corpedys':
            this.filterType = 'CORPEDYS';
            break;
          case 'responsable_acteo':
            this.filterType = 'ALTEO France';
            break;
          case 'responsable_bureau_etude':
            this.filterType = 'ISO 33';
            break;
          case 'responsable_mairie':
            this.filterType = 'NOVAE MAIRIE';
            break;
          case 'responsable_gse':
            this.filterType = 'GSE';
            break;
          // case 'responsable_filiale':
          //   this.filterFiliale = this.getUserData.filiale_name;
        }
      }
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    checkAllFunction() {
      this.getFraisVisionFilialeData.map(item => {
        item.check = this.checkAll;
        item.projects.map(itemD => (itemD.check = this.checkAll));
      });
    },
    checkAllFunctionDetails(data) {
      data.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemD => (itemD.check = item.check));
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    setLocalStorageVisionFiliale() {
      localStorage.setItem(
        'vision-filiale',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          week: this.filterWeek,
          filiale: this.filterFiliale,
          depot: this.filterDepot,
          type: this.filterType,
          statue: this.filterStatut,
          search: this.searchValue
        })
      );
    }
  },
  async mounted() {
    this.updateInterface('gestion frais');
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('vision-filiale')) {
      this.filteryear = JSON.parse(localStorage.getItem('vision-filiale')).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('vision-filiale')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('vision-filiale')
      ).month;
      this.filterDepot = JSON.parse(
        localStorage.getItem('vision-filiale')
      ).depot;
      this.filterWeek = JSON.parse(localStorage.getItem('vision-filiale')).week;
      this.filterType = JSON.parse(localStorage.getItem('vision-filiale')).type;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('vision-filiale')
      ).filiale;
      this.filterStatut = JSON.parse(
        localStorage.getItem('vision-filiale')
      ).statue;
      this.searchValue = JSON.parse(
        localStorage.getItem('vision-filiale')
      ).search;
    } else {
      this.year = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageVisionFiliale();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.checkRole();
    this.handleFilter();
  },
  components: {
    EditableInput,
    templateFraisVisionFiliale: () =>
      import('./component/fraisGlobale/templateFraisVisionFiliale.vue'),
    exractionBdd: () => import('./component/fraisGlobale/exractionBdd.vue'),
    sendMailGestionFrais: () =>
      import('./component/fraisGlobale/sendMailGlobalFrais.vue'),
    exractionBddInvalid: () =>
      import('./component/fraisGlobale/exractionBddInvalid.vue'),
    // refreshVisionViliale: () => import('./refreshVisionViliale.vue'),
    globalVlidation: () =>
      import('./component/fraisGlobale/globalValidation.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    validationStatueInstallateur: () =>
      import('./component/ValidationStatueInstallateur.vue'),
    iconInfoComponent: () => import('./component/iconInfoComponent.vue'),
    generationFactureAvoirFrais: () =>
      import('./component/generationFactureAvoirFrais.vue'),
    paiementColumnComponent: () =>
      import('./component/paiementColumnComponent.vue')
  },
  watch: {
    getErrorFraisVisionFiliale: function() {
      if (this.getErrorFraisVisionFiliale != null) {
        const objectFlash = {
          blockClass: 'custom-block-class',
          contentClass: 'custom-content-class',
          message: ''
        };
        if (Array.isArray(this.getErrorFraisVisionFiliale)) {
          for (let e in this.getErrorFraisVisionFiliale) {
            objectFlash.message =
              objectFlash.message + ' ' + this.getErrorFraisVisionFiliale[e];
          }
        } else {
          objectFlash.message = this.getErrorFraisVisionFiliale;
        }
        this.flashMessageId = this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          blockClass: objectFlash.blockClass,
          contentClass: objectFlash.contentClass,
          message: objectFlash.message
        });
      } else {
        this.flashMessage.deleteMessage(this.flashMessageId);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.flashMessage.deleteMessage(this.flashMessageId);
    next();
  }
};
</script>
<style scoped lang="scss">
.bdd-frais {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableFraisBdd {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
  }
  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .align-icon {
    margin-right: 7px;
    color: #ffff;
    font-size: 10px;
  }

  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
  }
}
</style>

<style lang="scss">
.custom-block-class {
  width: 220px !important;
  z-index: 5;
  p {
    margin-bottom: 0px;
  }
}
.custom-content-class {
  font-size: 10px !important;
}
.color {
  border-left: none !important;
}
.justify-content-sb-frais-globale {
  display: block !important;
  line-height: 11px !important;
}
.footer-total-bg {
  background-color: #dfdef4;
}
.customTableFraisBdd .table.b-table > caption {
  display: table-footer-group !important;
}
.table-rapport-style .customTableFraisBdd td {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}
.table-rapport-style .customTableFraisBdd {
  td,
  th {
    font-size: 9px;
  }
  .btn-anomalie {
    padding: 0px;
  }
}
.table-rapport-style .customTableFraisBddRezise {
  td,
  th {
    min-width: 100px;
    width: 100px;
  }
}
</style>
